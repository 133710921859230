function swiper() {
  var swiper = new Swiper(".mySwiper", {
    effect: "cards",
    grabCursor: true,
  });
}
function swiper1() {
  var swiper = new Swiper(".mySwiper", {
    slidesPerView: 1,
    spaceBetween: 10,

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });
}

function moblieswiper() {
  var swiper = new Swiper(".mySwiperMoblie", {
    slidesPerView: 3,
    spaceBetween: 30,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });
}

function convertToImage() {
  window.onload = function () {
    var canvas = document.getElementById("canvas");
    var context = canvas.getContext("2d");
    context.font = "20px Georgia";
    var ele = document.getElementById("contentToWrite1");
    var ele1 = document.getElementById("contentToWrite2");
    if (ele.textContent) {
      // for firefox
      context.fillText(ele.textContent, 10, 90);
      context.fillText(ele1.textContent, 10, 110);
    } else {
      // for other browser
      context.fillText(ele.innerText, 10, 90);
      context.fillText(ele1.innerText, 10, 110);
    }
  };
}
function exportImage() {
  var img = document.getElementById("exportedImage");
  img.src = canvas.toDataURL("image/png");
  console.log("img", img.src);
}
function googleInitialize() {
  window["googleSDKLoaded"] = () => {
    window["gapi"].load("auth2", () => {
      this.auth2 = window["gapi"].auth2.init({
        client_id:
          "631867203803-gfnbuj33563dmuorhmfm6cv2prqasulq.apps.googleusercontent.com",
        cookie_policy: "single_host_origin",
        scope: "profile email",
      });
      this.prepareLogin();
    });
  };
  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    // js.src = 'https://apis.google.com/js/platform.js?onload=googleSDKLoaded';
    // fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "google-jssdk");
}
function prepareLogin() {
  this.auth2.attachClickHandler(
    this.loginElement.nativeElement,
    {},
    (googleUser) => {
      let profile = googleUser.getBasicProfile();
      console.log("Token || " + googleUser.getAuthResponse().id_token);
      console.log("Image URL: " + profile.getImageUrl());
      console.log("Email: " + profile.getEmail());
    },
    (error) => {
      alert(JSON.stringify(error, undefined, 2));
    }
  );
}

function phyllo(item) {
  console.log(item);
  const config = {
    clientDisplayName: "Summarizly AI", // the name of your app that you want the creators to see while granting access
    environment: "staging", // the mode in which you want to use the SDK,  'sandbox' or 'production'
    userId: item.userId, // the unique user_id parameter returned by Phyllo API when you create a user (see https://docs.getphyllo.com/docs/api-reference/reference/openapi.v1.yml/paths/~1v1~1users/post)
    token: item.token,
  };

  const phylloConnect = PhylloConnect.initialize(config);

  phylloConnect.on("accountConnected", (accountId, workplatformId, userId) => {
    localStorage.setItem("accountId", accountId);
    localStorage.setItem("userId", userId);
    localStorage.setItem("phylloSdkToken", item.token);

    // gives the successfully connected account ID and work platform ID for the given user ID
    console.log(
      `onAccountConnected: ${accountId}, ${workplatformId}, ${userId}`
    );
  });
  phylloConnect.on(
    "accountDisconnected",
    (accountId, workplatformId, userId) => {
      // gives the successfully disconnected account ID and work platform ID for the given user ID
      console.log(
        `onAccountDisconnected: ${accountId}, ${workplatformId}, ${userId}`
      );

      localStorage.setItem("accountId", accountId);
    }
  );
  phylloConnect.on("tokenExpired", (userId) => {
    // gives the user ID for which the token has expired
    console.log(`onTokenExpired: ${userId}`); // the SDK closes automatically in case the token has expired, and you need to handle this by showing an appropriate UI and messaging to the users
  });
  phylloConnect.on("exit", (reason, userId) => {
    // indicates that the user with given user ID has closed the SDK and gives an appropriate reason for it
    console.log(`onExit: ${reason}, ${userId}`);
    // Define the URL of the API endpoint
    // if (item.environment == "https://user.dev.summarizly.ai/v1/") {
    // const apiUrl = "https://user.dev.summarizly.ai/v1/user/me";

    // }else {
    // const apiUrl = "https://user.summarizly.ai/v1/user/me";
    // }
    const apiUrl = "https://user.summarizly.ai/v1/user/me";

    let loginToken = localStorage.getItem("token");

    const authToken = `Bearer ${loginToken}`;
    let account_id = localStorage.getItem("accountId");
    // Create a JavaScript object with the data you want to send in the request body
    const accountDetailsSave = {
      account_id: account_id,
      token: item.token,
      user_Id: item.userId,
    };
    // Define the request headers
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };

    // Create the request configuration
    const requestOptions = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(accountDetailsSave), // Convert the data to a JSON string
    };

    // Make the POST request using fetch
    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        localStorage.removeItem("accountId");
        localStorage.removeItem("userId");
        localStorage.removeItem("phylloSdkToken");
        location.reload();
        console.log(JSON.stringify(response));
        return response.json(); // Parse the response as JSON
      })
      .then((responseData) => {
        // Handle the response data
        console.log(responseData);
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error:", error);
      });
  });
  phylloConnect.on("connectionFailure", (reason, workplatformId, userId) => {
    // optional, indicates that the user with given user ID has attempted connecting to the work platform but resulted in a failure and gives an appropriate reason for it
    console.log(`onConnectionFailure: ${reason}, ${workplatformId}, ${userId}`);
  });

  let phylloButton = document.getElementById("phylloButton");

  phylloButton.onclick = phylloConnect.open();
}

// import commentBox from "commentbox.io/";
// import { commentBox } from "commentbox.io";
// function commentBoxSection() {
//   commentBox("5705670485606400-proj");
// }

/**
 *  RECOMMENDED CONFIGURATION VARIABLES: EDIT AND UNCOMMENT THE SECTION BELOW TO INSERT DYNAMIC VALUES FROM YOUR PLATFORM OR CMS.
 *  LEARN WHY DEFINING THESE VARIABLES IS IMPORTANT: https://disqus.com/admin/universalcode/#configuration-variables    */
/*
    var disqus_config = function () {
    this.page.url = PAGE_URL;  // Replace PAGE_URL with your page's canonical URL variable
    this.page.identifier = PAGE_IDENTIFIER; // Replace PAGE_IDENTIFIER with your page's unique identifier variable
    };
    */
function comments() {
  var disqus_config = function () {
    this.page.url = "http://localhost:4200/feed"; // Replace PAGE_URL with your page's canonical URL variable
    this.page.identifier = "/feed"; // Replace PAGE_IDENTIFIER with your page's unique identifier variable
  };

  (function () {
    // DON'T EDIT BELOW THIS LINE
    var d = document,
      s = d.createElement("script");
    s.src = "https://summarizly-ai.disqus.com/embed.js";
    s.setAttribute("data-timestamp", +new Date());
    (d.head || d.body).appendChild(s);
  })();
}
